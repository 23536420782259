<template>
  <v-container fluid class="py-6">
    <v-row class="mx-6">
      <v-col>
        <v-card class="card-shadow px-4 py-4 overflow-hidden border-radius-xl">
          <v-row>
            <v-col cols="auto">
              <v-avatar width="74" height="74" class="shadow border-radius-lg">
                <img
                  src="@/assets/img/team-4.jpg"
                  alt="Avatar"
                  class="border-radius-lg"
                />
              </v-avatar>
            </v-col>
            <v-col cols="auto" align-self="center">
              <h4 class="mb-1 text-h3 text-grt font-weight-bold">
                新增使用者
              </h4>
            </v-col>
            <v-spacer></v-spacer>
            <v-btn icon class="mx-4 my-4" @click="$router.go(-1)">
              <v-icon large>mdi-close</v-icon>
            </v-btn>
          </v-row>
        </v-card>
        <user-basic-info :edit_mode="edit_mode_enum.Create"></user-basic-info>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { edit_mode_enum } from "@/definitions.js"
import UserBasicInfo from "./Widgets/UserBasicInfo.vue";
export default {
  name: "Add-User",
  components: {
    UserBasicInfo,
  },
  data() {
    return {
      edit_mode_enum: edit_mode_enum,
    };
  },
  mounted(){
    console.log("here")
  }
};
</script>
